import "./header.css";
import Logo from "../../assets/img/PostoakNewLogo.jpg";
import { Header_String } from "../../utils/string";
import NavigationLinkHeader from "./navigationLinkHeader";
const Header = () => {
  return (
    <>
      <div className="header_top_box_Bg">
        
      <div className="header_top_box_white"/>
        <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
          <div className="header_top_box">
            <div>
              <img src={Logo} alt="logo" className="header_logo" />
            </div>
            <div className="header_top_Info_box">
              <div className="header_top_Info_Text_Address">
                {Header_String.Address}
              </div>
              <div className="header_top_Info_Text_Number">
                {Header_String.Phone_Number}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header_menu">
        <NavigationLinkHeader
        //   activeTab={activeTab}
        />
      </div>
    </>
  );
};
export default Header;
