export const Header_String = {
  Address: "Serving the USA and International Clients | Located in Boerne, TX",
  Phone_Number: "830-755-4500",
};

export const header = {
  HOME: "Home",
  MACHINES: "Machines",
  MACHINES_SALE: "Machines for Sale/Rent",
  OUR_PRO: "Our Products",
  ABOUT_US: "About Us",
  CONTACT_US: "Contact Us",
};

export const Footer_String = {
  Equipment: "Know Your Equipment's Current World Market Value.",
  CONTACT_US_TODAY: "Contact Us Today",
  sentences_1:
    "Post Oak Machinery uses 25 years of experience in the Heavy Equipment Market to acquire equipment for its rental fleet and also be able to sell quality, low hour machines to it customers.",
  sentences_2:
    "Give us a call for your Rental, Lease, Trade and Purchase needs for Heavy Equipment.",
  Name: "Ben Skipper",
  SubTitle: "Post Oak Machinery",
  PHYSICAL: "Physical",
  Address: "28720 IH 10 West Boerne, Texas 78006",
  Cell: "Cell",
  Number: "210-416-5172",
  Office: "Office",
  OfficeNumer: "830-755-4500",
  Fax: "Fax",
  FaxNumber: "210-568-4484",
  Email:"Email",
  EmailAddres:"ben@postoakmachinery.com"
};

export const Dashboard_String = {
  Machines: "Machines for Sale/Rent",
};

export const Model_String = {
  Equipment_Need:
    "Send us a message and tell us how we can help and what equipment you need",
};
