function getUniqueListBy(arr, key) {
  return [...new Map(arr?.map(item => [item[key], item])).values()]
}

function currencyFormat(num) {
  return num?.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export {getUniqueListBy, currencyFormat,goToTop}
