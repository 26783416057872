import api from "../api/api"
import postApi from "../api/postApi"
import * as url from '../api/url'

// Get Machine Details By ID
export const getMachineDetailsByID = (ID) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_MACHINES_DETAILS + `?applicationId=${url.ApplicationId}&assetAdvertisementId=${ID}`)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}

export const getAllMachinesList =()=>{
    return new Promise(async (resolve, reject) => {
      return api
        .get(url.GET_CATEGORY_LIST + `?applicationId=${url.ApplicationId}`)
        .then(response => {
          if (response) {
            resolve(response)
          }
        })
        .catch(err => {
          reject(err.response)
        })
    })
  }

  // Post Machine List
export const postMachinesList = (payload) => {
  payload={...payload,ApplicationId:url.ApplicationId }

    return new Promise(async (resolve, reject) => {
      return postApi
        .post(url.GET_MACHINES_LIST , payload)
        .then(response => {
          if (response) {
            resolve(response)
          }
        })
        .catch(err => {
          reject(err.response)
        })
    })
  }

  // Get Machine Details By ID
export const postSaveAdvertiseVisitLogs = (payload) => {
  return new Promise(async (resolve, reject) => {
    return postApi
      .post(url.POST_SAVEADVERTISE,payload)
      .then(response => {
        if (response) {
          resolve(response)
        }
      })
      .catch(err => {
        reject(err.response)
      })
  })
}