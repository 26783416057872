export const ApplicationId = "bffe1ff8-9453-40f1-a970-21fec7999761";
export const ProURL = "https://dealerwebservice-api.azurewebsites.net/";
export const DevURL = "https://dealerwebservice-api-dev.azurewebsites.net/";
export const POST_CONTACT_US = "DealerWebApp/SendContactEmail";
export const GET_CATEGORY_LIST =
'DealerWebApp/GetTierWiseAssetCategorizationSummary';
export const GET_MACHINES_DETAILS =
  'DealerWebApp/GetInventorySummaryById'
export const GET_MACHINES_LIST = 'DealerWebApp/GetInventory';
export const POST_SAVEADVERTISE ='DealerWebApp/SaveAdvertiseVisitLogs'
export const GET_TESTIMONIAL='DealerWebApp/GetTestimonialSummary'
export const URL = ProURL;
