import { Link } from "react-router-dom";
import { useState } from "react";
import "react-responsive-modal/styles.css";

import { header } from "../../utils/string";
import MenuIcon from "../../assets/icons/menu.svg";
import ContactUsModel from "../../component/Model/Contact_Us/ContactUsModel";
import { goToTop } from "../../utils/helper";
const NavigationLinkHeader = () => {
  const [modalIsOpen,setIsOpen] = useState(false);

  return (
    <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
      <div className="navigation_link_header">
        <div className="navigation_link_header_part">
          <Link className="NavLink" to={"/"} onClick={goToTop}>
            <div className="navigation_header_link">{header.HOME}</div>
          </Link>
          <Link className="NavLink" to={"/machines"} onClick={goToTop}>
            <div className="navigation_header_link">{header.MACHINES}</div>
          </Link>
          <Link className="NavLink" onClick={() => setIsOpen(true)}>
            <div className="navigation_header_link">{header.CONTACT_US}</div>
          </Link>
        </div>
      </div>

      <div className="navigation_link_header_mini_screen">
        <div className="dropdown">
          <button className="dropbtn">
            <img src={MenuIcon} alt="" className="dropbtn_icon" />
          </button>
          <div className="dropdown-content">
            <Link className="NavLink" to={"/"} onClick={goToTop}>
              <div className="navigation_header_link">{header.HOME}</div>
            </Link>
            <Link className="NavLink" to={"/machines"} onClick={goToTop}>
              <div className="navigation_header_link">{header.MACHINES}</div>
            </Link>
            <Link className="NavLink" onClick={() => setIsOpen(true)}>
              <div className="navigation_header_link">{header.CONTACT_US}</div>
            </Link>
          </div>
        </div>
      </div>

      <ContactUsModel modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
export default NavigationLinkHeader;
