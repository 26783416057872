import { useEffect, useState } from "react";
import ContactUsModel from "../../component/Model/Contact_Us/ContactUsModel";
import { Footer_String } from "../../utils/string";
import upArrow from "../../assets/icons/up-arrow.svg";
import "./footer.css";
import { goToTop } from "../../utils/helper";
const Footer = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  return (
    <>
      <ContactUsModel modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      {showTopBtn && (
        <button
          type="button"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="fixed inline-block p-3 bg-black flex flex-col items-center text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-black hover:shadow-lg focus:bg-black focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out bottom-5 right-5 text-[16px] capitalize "
          id="btn-back-to-top"
          onClick={goToTop}
        >
          <img src={upArrow} className="w-[16px]" alt="" />
          <div className="mt-1">Top</div>
        </button>
      )}

      <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
        

        <div className="contact_grid_box_footer">
          <div className="contact_grid_box_footer_left">
            <div className="contact_grid_box_footer_left_sentences_big_screen">
              <br />
              <div className="contact_grid_box_footer_left_text">
                {Footer_String.sentences_2}
              </div>
            </div>
            <div>
              <div className="contact_grid_box_footer_right_label font-weight-600">
                {Footer_String.Name}
              </div>
              <div className="contact_grid_box_footer_right_label">
                {Footer_String.SubTitle}
              </div>
              <div className="contact_grid_box_footer_right_label">
                <strong>{Footer_String.PHYSICAL}</strong>:{" "}
                <a href="https://goo.gl/maps/C5DEEMhG6NjvZE9cA" className="contact_grid_box_footer_right_label underline" target="_blank" rel="noreferrer">{Footer_String.Address}</a>
              </div>
              <div className="contact_grid_box_footer_right_label">
                <strong>{Footer_String.Cell}</strong>:
                <a
                  href={`tel:${Footer_String.Number}`}
                  className="contact_grid_box_footer_right_label_a_tag"
                >
                  {" "}
                  {Footer_String.Number}
                </a>
              </div>
              <div className="contact_grid_box_footer_right_label">
                <strong>{Footer_String.Office}</strong>:
                <a
                  href={`tel:${Footer_String.OfficeNumer}`}
                  className="contact_grid_box_footer_right_label_a_tag"
                >
                  {" "}
                  {Footer_String.OfficeNumer}
                </a>
              </div>
              <div className="contact_grid_box_footer_right_label">
                <strong>{Footer_String.Fax}</strong>:
                <a
                  href={`fax:${Footer_String.FaxNumber}`}
                  className="contact_grid_box_footer_right_label_a_tag"
                >
                  {" "}
                  {Footer_String.FaxNumber}
                </a>
              </div>
              <div className="contact_grid_box_footer_right_label">
                <strong>{Footer_String.Email}</strong>:
                <a
                  href={`mailto:${Footer_String.OfficeNumer}`}
                  className="contact_grid_box_footer_right_label_a_tag"
                >
                  {" "}
                  {Footer_String.EmailAddres}
                </a>
              </div>
            </div>
          </div>
          <div className="contact_grid_box_footer_right">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.824704509549!2d-98.6707248!3d29.724836600000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c707aae9c8779%3A0x57de7d489dce53b8!2s28720%20I-10%2C%20Boerne%2C%20TX%2078006%2C%20USA!5e0!3m2!1sen!2sin!4v1683736162405!5m2!1sen!2sin"
              width="100%"
              height={"400px"}
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="Footer_Text">
          {Footer_String.Equipment}{" "}
          <span
            className="Footer_Text_Underline"
            onClick={() => setIsOpen(!modalIsOpen)}
          >
            {Footer_String.CONTACT_US_TODAY}
          </span>
        </div>
      </div>
    </>
  );
};
export default Footer;
