import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import JCB5Img from "../../assets/img/JCB1.jpeg";
import JCB2Img from "../../assets/img/JCB2.jpeg";
import JCB3Img from "../../assets/img/JCB3.jpeg";
import JCB4Img from "../../assets/img/JCB4.jpeg";
import "./dashboard.css";
import { Dashboard_String, Footer_String } from "../../utils/string";
const Dashboard = () => {
  const carouselImg = [
    {
      original: JCB5Img,
      thumbnail: JCB5Img,
    },
    {
      original: JCB4Img,
      thumbnail: JCB4Img,
    },
    {
      original: JCB3Img,
      thumbnail: JCB3Img,
    },
    {
      original: JCB2Img,
      thumbnail: JCB2Img,
    },
  ];
  return (
    <>
      <div className="container mx-auto px-4 sm:px-4 md:px-4 lg:px-4 xl:px-24 2xl:px-44">
        <div className="dashboard">
          <div className="Gallery_Box">
            <ImageGallery
              items={carouselImg}
              infinite={true}
              autoPlay={true}
              useBrowserFullscreen={false}
              originalWidth={CarouselStyle.width}
              originalHeight={CarouselStyle.height}
              slideInterval={5000}
              showFullscreenButton={false}
              showThumbnails={false}
              showPlayButton={false}
            />
          </div>
          <div className="Machines_Rent">
            <div>
              <div className="contact_grid_box_footer_left_sentences_sm_screen">
                <div className="contact_grid_box_footer_left_text">
                  {Footer_String.sentences_1}
                </div>
                <br />
                <div className="contact_grid_box_footer_left_text">
                  {Footer_String.sentences_2}
                </div>
              </div>

              <div className="Machines_Rent_Text">
                {Dashboard_String.Machines}
              </div>
            </div>
            <div className="Machines_Rent_img_main_box">
              <div className="Machines_Rent_img_sub_box">
                <div className="Machines_Rent_img_div">
                  <img src={JCB5Img} alt="" className="Machines_Side_Img" />
                </div>
                <div className="Machines_Rent_img_div">
                  <img src={JCB2Img} alt="" className="Machines_Side_Img" />
                </div>
              </div>
              <div className="Machines_Rent_img_sub_box">
                <div className="Machines_Rent_img_div">
                  <img src={JCB3Img} alt="" className="Machines_Side_Img" />
                </div>
                <div className="Machines_Rent_img_div">
                  <img src={JCB4Img} alt="" className="Machines_Side_Img" />
                </div>
              </div>
            </div>
          </div>
        </div><div className="contact_grid_box_footer_top">
          <div className="contact_grid_box_footer_left_text_sentences_1 contact_grid_box_footer_left_sentences_big_screen">
            {Footer_String.sentences_1}
          </div>
        </div>
      </div>
    </>
  );
};
const CarouselStyle = {
  width: "1080px",
  height: "600px",
  thumbnailWidth: "100px",
  captionStyle: {
    display: "none",
  },
  layout: {
    textAlign: "center",
    maxWidth: "1080px",
    maxHeight: "100%",
    minHeight: "100%",
    margin: "50px auto 90px auto",
  },
};
export default Dashboard;
