import React from "react";
import MachineList from "../../component/Machine_List";
const Machines = () => {
  return (
    <React.Fragment>
      <div>
        <MachineList/>
      </div>
    </React.Fragment>
  );
};
export default Machines;
