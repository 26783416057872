import "./App.css";
import Footer from "./layout/footer/footer";
import Header from "./layout/header/header.js";
import Dashboard from "./page/dashboard/dashboard";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  BrowserRouter, // should be single
  Routes, // can have multiple; even inside component but append "/*" in path of that compoent
  Route
} from "react-router-dom";
import Machines from "./page/Machines";
import Details from "./page/Machines/detail";

function App() {
  return (
    <BrowserRouter><div className="App">
      <ToastContainer />
      <header className="App-header">
        <Header />
      </header>
      <Routes>
        <Route path="/" element={<Dashboard />}>
        </Route>
        <Route path="/machines" element={<Machines />}>
        </Route>
        <Route path="/detail/:id" element={<Details />}>
        </Route> 
      </Routes>
      <Footer />
    </div>
    </BrowserRouter>
  );
}

export default App;
