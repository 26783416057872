import { Modal } from "react-responsive-modal";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { Formik } from "formik";
import { useRef, useState } from "react";
import CustomButton from "../../custom_components/CustomButton";
import { postContactUs } from "../../../services/contactUsService";
import { ApplicationId } from "../../../api/url";
import { Model_String } from "../../../utils/string";
import { toast } from "react-toastify";

const ContactUsModel = ({ modalIsOpen, setIsOpen, mID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const reCaptchaRef = useRef(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const AccSchema = Yup.object().shape({
    firstname: Yup.string().required("Please Enter First Name"),
    lastname: Yup.string().required("Please Enter Last Name"),
    email: Yup.string()
      .email("Enter Valid Email Address")
      .required("Please Enter E-mail Address"),
    message: Yup.string().required("Please Enter message"),
    phone: Yup.string().required("Please Enter Phone Number"),
    Make: Yup.string().required("Please Enter Make"),
    Model: Yup.string().required("Please Enter Model"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });

  const handleSubmitForm = (value, resetForm) => {
    const htmlForm = `
          <div style="font-size:25px">
            <div>
              <table>
                <tr style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
      }</td>
                </tr>
                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
      }</td>
                </tr>
                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
      }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
      }</a></td>
                </tr>
                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
      }</td>
                </tr>
                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
      }</td>
                </tr>

                
                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Model</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.Model ?? ""
      }</td>
                </tr>

                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Make</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.Make ?? ""
      }</td>
                </tr>

                <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
      }</td>
                </tr>
              </table>
            <div>
          </div>
      `;

    const htmlFormWidthInventory = `
          <div style="font-size:25px">
            <div>
              <table>
                <tr style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""
      }</td>
                </tr>
                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""
      }</td>
                </tr>
                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${value?.email ?? ""
      }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""
      }</a></td>
                </tr>
                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""
      }</td>
                </tr>
                <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 0px 0px;color:#000;">Inventory</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.inventory ?? ""
      }</td>
          </tr>
                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""
      }</td>
                </tr>

                
                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Model</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.Model ?? ""
      }</td>
                </tr>

                <tr  style="border-top:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Make</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.Make ?? ""
      }</td>
                </tr>

                <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
                  <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
                  <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${value?.message ?? ""
      }</td>
                </tr>
              </table>
            <div>
          </div>
      `;
    setIsLoading(true);
    let payload = {
      HtmlContent: mID ? htmlFormWidthInventory : htmlForm,
      ApplicationId: ApplicationId,
    };
    postContactUs(payload)
      .then((res) => {
        reCaptchaRef?.current?.reset();
        setIsLoading(false);
        toast.success(res.data);
        resetForm();
        closeModal();
      })
      .catch((err) => {
        toast.error(err.data);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Modal open={modalIsOpen} onClose={closeModal} center>
        <div className="Modal">
          {isLoading && (<div className="loader_Box">
            <div className="loader"></div>
          </div>)}
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              email: "",
              phone: "",
              location: "",
              message: "",
              inventory: `${mID?.assetAdvertisementId}-${mID?.year || ''
                }-${mID?.manufacturer?.name}-${mID?.model?.number
                }`,
              Model: mID ? mID?.model?.number : "",
              Make: mID ? mID?.manufacturer?.name : "",
              reCaptcha: "",
            }}
            validationSchema={AccSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmitForm(values, resetForm);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="contact_grid_box_top">
                  <div className="contact_field_Header_text">
                    {Model_String.Equipment_Need}
                  </div>
                </div>
                <div className="contact_grid_box_top">
                  <div className="contact_field">
                    <label className="contact-input-label">First Name</label>
                    <input
                      type="text"
                      id="userfirstname"
                      name="firstname"
                      className="contact_input"
                      value={values.firstname}
                      onChange={(e) => handleChange(e)}
                    />

                    {errors.firstname && touched.firstname && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="error_message"
                      >
                        {errors.firstname}
                      </div>
                    )}
                  </div>
                  <div className="contact_field">
                    <label className="contact-input-label">Last Name</label>
                    <input
                      type="text"
                      id="userfirstname"
                      name="lastname"
                      className="contact_input"
                      value={values.lastname}
                      onChange={(e) => handleChange(e)}
                    />

                    {errors.lastname && touched.lastname && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="error_message"
                      >
                        {errors.lastname}
                      </div>
                    )}
                  </div>
                </div>
                <div className="contact_grid_box_top">
                  <div className="contact_field">
                    <label className="contact-input-label">Email</label>
                    <input
                      type="email"
                      id="useremail"
                      name="email"
                      className="contact_input"
                      value={values.email}
                      onChange={(e) => handleChange(e)}
                    />

                    {errors.email && touched.email && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="error_message"
                      >
                        {errors.email}
                      </div>
                    )}
                  </div>
                  <div className="contact_field">
                    <label className="contact-input-label">Phone Number</label>
                    <input
                      type="number"
                      id="userfirstname"
                      name="phone"
                      className="contact_input"
                      value={values.phone}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.phone && touched.phone && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="error_message"
                      >
                        {errors.phone}
                      </div>
                    )}
                  </div>
                </div>
                <div className="contact_grid_box_top">
                  <div className="contact_field">
                    <label className="contact-input-label">Make</label>
                    <input
                      type="text"
                      id="Make"
                      name="Make"
                      className="contact_input"
                      value={values.Make}
                      onChange={(e) => handleChange(e)}
                    />

                    {errors.Make && touched.Make && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="error_message"
                      >
                        {errors.Make}
                      </div>
                    )}
                  </div>
                  <div className="contact_field">
                    <label className="contact-input-label">Model</label>
                    <input
                      type="text"
                      id="Model"
                      name="Model"
                      className="contact_input"
                      value={values.Model}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.Model && touched.Model && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="error_message"
                      >
                        {errors.Model}
                      </div>
                    )}
                  </div>
                </div>

                {mID && (
                  <div className=" contact_grid_box_top contact_field">
                    <label className=" contact-input-label">
                      Inventory
                    </label>
                    <input
                      type="text"
                      id="location"
                      name="location"
                      className="contact_input"
                      value={values?.inventory}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                )}

                <div className="contact_grid_box_top contact_field">
                  <label className=" contact-input-label">Location</label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    className="contact_input"
                    value={values.location}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="contact_grid_box_top contact_field">
                  <label className=" contact-input-label">Message</label>
                  <textarea
                    type="textarea"
                    id="message"
                    name="message"
                    rows="3"
                    className="contact_input_textarea"
                    value={values.message}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors.message && touched.message && (
                    <div
                      style={{ fontSize: 14, textAlign: "left" }}
                      className="error_message"
                    >
                      {errors.message}
                    </div>
                  )}
                </div>
                <div className="contact_grid_box_top contact_field google_recaptch_box">
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    size="normal"
                    sitekey={"6Lex9tslAAAAAMVOPDOzTJV2BafKP2dcT0GyOgX6"}
                    onChange={(captchaCode) => {
                      setFieldValue("reCaptcha", captchaCode);
                    }}
                  />
                  {errors.reCaptcha && touched.reCaptcha && (
                    <div
                      style={{ fontSize: 14, textAlign: "left" }}
                      className="error_message"
                    >
                      {errors.reCaptcha}
                    </div>
                  )}
                </div>
                <div className="button_grid">
                  <CustomButton
                    type="button"
                    lable={"cancel"}
                    CustomButtonClass="cancel_btn"
                    onClick={closeModal}
                  />
                  <CustomButton
                    type="submit"
                    disabled={isLoading}
                    lable={"Submit"}
                    CustomButtonClass={
                      isLoading ? "Submit_loading_btn" : "Submit_btn"
                    }
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};
export default ContactUsModel;
